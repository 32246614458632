import { SizeEnum } from "../config";
import "./ResolutionBox.less";

interface IResolutionBox {
  size: SizeEnum;
}

const ResolutionBox: React.FC<IResolutionBox> = (props) => {
  const { size } = props;
  return (
    <>
      <div className={`size${size.replace(":", "-")} resolution-box`}></div>
    </>
  );
};

export default ResolutionBox;
