export const usecChangeCreationStore = (lastArr) => {
  // ...JSON.parse(JSON.stringify(firsetArr)),
  const nArr = JSON.parse(JSON.stringify(lastArr));
  return nArr.map((item, index) => {
    return {
      key: index++,
      storyboardName: [
        {
          type: "paragraph",
          content: [
            {
              type: "text",
              text: item.storyboardName,
            },
          ],
        },
      ],
      pictureDescription: [
        {
          type: "paragraph",
          content: [
            {
              type: "text",
              text: item.pictureDescription,
            },
          ],
        },
      ],
      caption: [
        {
          type: "listItem",
          content: [
            {
              type: "paragraph",
              content: [
                {
                  type: "text",
                  text: item.caption,
                },
              ],
            },
          ],
        },
      ],
      materials: item.materials,
    };
  });
};
