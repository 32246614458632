import { Input } from "@arco-design/web-react";
import { RefInputType } from "@arco-design/web-react/es/Input";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import "./EditText.less";

interface IEditText {
  style?: React.CSSProperties;
  name: string;
  disStopPropagation?: boolean;
  handleInputEndEnter: (data: string) => void;
  disabed?: boolean;
  size?: "mini" | "small" | "default" | "large";
  inputStyle?: React.CSSProperties;
}

export interface IforwardRef {
  toggleIsEdit: () => void;
}

const EditText = forwardRef<IforwardRef, IEditText>((props, ref) => {
  const {
    name,
    handleInputEndEnter,
    style,
    disStopPropagation,
    disabed,
    size,
    inputStyle,
  } = props;
  const [inputValue, setInputValue] = useState(name);
  const [isEdit, setIsEdit] = useState(false);
  const inputRef = useRef<RefInputType>(null);
  const toggleIsEdit = () => {
    setIsEdit(!isEdit);
  };

  useEffect(() => {
    setInputValue(name);
  }, [name]);

  useEffect(() => {
    if (isEdit) {
      inputRef.current.focus();
    }
  }, [isEdit]);

  useImperativeHandle(ref, () => ({
    toggleIsEdit,
  }));
  return (
    <div
      className="edit-wrapper"
      onClick={(e) => {
        if (!disStopPropagation) {
          e.stopPropagation();
        }
      }}>
      {!isEdit || !disabed ? (
        <p className="project-name" style={{ ...style }}>
          {name}
        </p>
      ) : (
        <Input
          style={inputStyle}
          size={size ? size : "default"}
          value={inputValue}
          onChange={(e) => {
            setInputValue(e);
          }}
          onClick={(e) => {
            if (!disStopPropagation) {
              e.stopPropagation();
            }
          }}
          ref={inputRef}
          onKeyDown={(e) => {
            if (e.code.toLocaleLowerCase() === "escape") {
              setIsEdit(false);
            }
          }}
          onBlur={() => {
            if (handleInputEndEnter) {
              handleInputEndEnter(inputValue);
            } else {
              console.error("请传入handleInputEndChange配置");
            }
          }}
          onPressEnter={(e) => {
            if (handleInputEndEnter) {
              handleInputEndEnter(e.target.value);
            } else {
              console.error("请传入handleInputEndChange配置");
            }
          }}
        />
      )}
    </div>
  );
});

EditText.displayName = "EditText";

export default EditText;
