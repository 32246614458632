export enum SizeEnum {
  Width9Height16 = "9:16",
  Width3Height4 = "3:4",
  Width1Height1 = "1:1",
  Width4Height3 = "4:3",
  Width16Height9 = "16:9",
}

const resolution = [
  {
    name: "9:16",
    title: "9:16",
  },
  {
    name: "3:4",
    title: "3:4",
  },
  {
    name: "1:1",
    title: "1:1",
  },
  {
    name: "4:3",
    title: "4:3",
  },
  {
    name: "16:9",
    title: "16:9",
  },
  // {
  //   name: "customized",
  //   title: "自定义",
  // },
];

export const subResolution: { [key in SizeEnum]: string[] } = {
  "9:16": ["1080*1920", "720*1280"],
  "3:4": ["1080*1440"],
  "1:1": ["1080*1080", "720*720"],
  "4:3": ["1280*960"],
  "16:9": ["1920*1080", "1280*720"],
};

export default resolution;
