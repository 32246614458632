import React, {
  forwardRef,
  ReactElement,
  ReactNode,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Checkbox, Popover, Tag } from "@arco-design/web-react";
import img from "@/assets/R-C.jpeg";
import mp3 from "@/assets/create-space/mp3.png";
import {
  IconMoreVertical,
  IconMusic,
  IconVideoCamera,
} from "@arco-design/web-react/icon";
import EditText, { IforwardRef } from "../EditText/EditText";
import "./MaterialCard.less";

export enum CardTypeEnum {
  MUSIC = "MUSIC",
  MOVIE = "MOVIE",
  PICTURE = "PICTURE",
}

type TagMapType = {
  [_ in CardTypeEnum]: React.ReactNode;
};

export interface ICard {
  /**
   * style
   */
  style?: React.CSSProperties;
  /**
   * 判断是否可以进行批量操作
   */
  isOperate?: boolean;
  /**
   * 展示图片url
   */
  img: string;
  shelLife?: string;
  type?: CardTypeEnum;
  time?: string;
  rightTopTag?: ReactElement;
  isHoverState?: boolean;
  /**
   * 卡片名称
   */
  name?: string;
  /**
   * 更新时间
   */
  updateTime?: string;
  /**
   * card的onClick事件阻止是否触发
   */
  isCardClickPrevented?: boolean;
  /**
   * 是否进行可以改名 默认可以改名
   */
  disRename?: boolean;
  actionBar?: ReactNode;
  checkBoxState?: boolean;
  bottomActionVisible?: boolean;
  onClick?: () => void;
  handleInputEndEnter?: (e: string) => void;
  handleCheckboxChange?: (e: boolean) => void;
}

export const toTypeGetTag = (type: CardTypeEnum) => {
  const tagMap: TagMapType = {
    [CardTypeEnum.MOVIE]: <Tag color="blue">视频</Tag>,
    [CardTypeEnum.MUSIC]: <Tag color="blue">音乐</Tag>,
    [CardTypeEnum.PICTURE]: <Tag color="blue">图片</Tag>,
  };
  return tagMap[type];
};

export const toTypeGetTimeTag = (type: CardTypeEnum, time: string) => {
  const tagMap: TagMapType = {
    [CardTypeEnum.MOVIE]: <div className="time-wrapper">{time}</div>,
    [CardTypeEnum.MUSIC]: <div className="time-wrapper">{time}</div>,
    [CardTypeEnum.PICTURE]: <></>,
  };
  return tagMap[type];
};

const toTypeGetImg = (type: CardTypeEnum, url?: string) => {
  const imgMap: TagMapType = {
    [CardTypeEnum.MOVIE]: (
      <img src={url ? url : img} style={{ height: "100%" }} />
    ),
    [CardTypeEnum.PICTURE]: <img src={url ? url : img} />,
    [CardTypeEnum.MUSIC]: <img className="mp3" src={mp3} />,
  };
  return imgMap[type];
};

export interface IMaterialRef extends IforwardRef {
  setIsTriggerActive: (e: boolean) => void;
}

const MaterialCard = forwardRef<IMaterialRef, ICard>((props, ref) => {
  const {
    img,
    isOperate,
    shelLife,
    type,
    time,
    rightTopTag,
    actionBar,
    name,
    checkBoxState,
    bottomActionVisible = true,
    isCardClickPrevented,
    onClick,
    handleInputEndEnter,
    handleCheckboxChange,
    disRename,
    updateTime,
    style,
  } = props;
  const [isTriggerActive, setIsTriggerActive] = useState(false);
  const editorInputRef = useRef<IforwardRef>();

  useImperativeHandle(
    ref,
    () => ({
      // setTriggerButtoActive: handleTriggerActive,
      setIsTriggerActive,
      toggleIsEdit: editorInputRef.current.toggleIsEdit,
    }),
    [props],
  );

  return (
    <>
      <figure
        style={{
          ...style,
          border: `1px solid ${checkBoxState ? "#165DFF" : "transparent"}`,
        }}
        className={`card-wrapper ${isOperate ? "opeater-box" : ""}`}
        onClick={() => {
          if (onClick && !checkBoxState && !isCardClickPrevented) {
            onClick();
          }
        }}>
        {toTypeGetImg(type, img)}
        {isOperate ? (
          <div className={`checkbox-wrapper ${checkBoxState ? "show" : ""}`}>
            <Checkbox
              onClick={(e) => {
                e.stopPropagation();
              }}
              checked={checkBoxState}
              onChange={(e, event) => {
                if (handleCheckboxChange) {
                  handleCheckboxChange(e);
                }
              }}
            />
          </div>
        ) : (
          <></>
        )}
        {type && !rightTopTag ? (
          <div className="type-wrapper">{toTypeGetTag(type)}</div>
        ) : (
          <></>
        )}
        {rightTopTag ? (
          <div className="type-wrapper">{rightTopTag}</div>
        ) : (
          <></>
        )}
        {time ? <>{toTypeGetTimeTag(type, time)}</> : <></>}
        {shelLife ? <div className="life-wrapper">{shelLife}</div> : <></>}
        {bottomActionVisible ? (
          <div
            className={`trigger-wrapper ${isTriggerActive ? "active" : ""}`}
            onClick={(e) => {
              e.stopPropagation();
            }}>
            <div className="msg">
              <EditText
                disabed={disRename}
                name={name}
                handleInputEndEnter={(e) => {
                  if (handleInputEndEnter) {
                    handleInputEndEnter(e);
                  }
                }}
                ref={editorInputRef}
              />
              <div className="time">更新于:{updateTime}</div>
            </div>
            {actionBar ? (
              <>
                <Popover
                  position="rt"
                  trigger={"hover"}
                  style={{ width: 150 }}
                  content={actionBar}
                  onVisibleChange={(e) => {
                    setIsTriggerActive(e);
                  }}>
                  <IconMoreVertical
                    style={{ cursor: "point" }}
                    className="more-icon"
                  />
                </Popover>
              </>
            ) : (
              <></>
            )}
          </div>
        ) : (
          <></>
        )}
      </figure>
    </>
  );
});

MaterialCard.displayName = "MaterialCard";

export default MaterialCard;
