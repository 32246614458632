import React from "react";
import { Link, Outlet } from "react-router-dom";
import { Layout } from "@arco-design/web-react";
import "./index.less";
import Header from "@/components/UI/Header";
import useData from "./useData";
import Menu from "@/components/UI/Menu";
const Sider = Layout.Sider;
const Content = Layout.Content;
const App = () => {
  const { menuList } = useData();
  return (
    <div className="layout-collapse-demo">
      <Header />
      <Layout>
        <div className="layout_sider">
          <Menu menuList={menuList as any} />
        </div>
        <Content className="content_box">
          <Outlet />
        </Content>
      </Layout>
    </div>
  );
};

export default App;
