/* eslint-disable @typescript-eslint/no-empty-function */
// Client constructor
function Client(revision) {
  this.revision = revision; // the next expected revision number
  this.state = synchronized_; // start state
}

Client.prototype.setState = function (state) {
  this.state = state;
};

// Call this method when the user changes the document.
// 当用户更改文档时触发
Client.prototype.applyClient = function (operation) {
  this.setState(this.state.applyClient(this, operation));
};

// Call this method with a new operation from the server
Client.prototype.applyServer = function (operation) {
  this.revision++;
  this.setState(this.state.applyServer(this, operation));
};

Client.prototype.serverAck = function () {
  this.revision++;
  this.setState(this.state.serverAck(this));
};

Client.prototype.serverReconnect = function () {
  if (typeof this.state.resend === "function") {
    this.state.resend(this);
  }
};

// Transforms a selection from the latest known server state to the current
// client state. For example, if we get from the server the information that
// another user's cursor is at position 3, but the server hasn't yet received
// our newest operation, an insertion of 5 characters at the beginning of the
// document, the correct position of the other user's cursor in our current
// document is 8.
Client.prototype.transformSelection = function (selection) {
  return this.state.transformSelection(selection);
};

// Override this method.
Client.prototype.sendOperation = function (revision, operation) {
  throw new Error("sendOperation must be defined in child class");
};

// Override this method.
Client.prototype.applyOperation = function (operation) {
  throw new Error("applyOperation must be defined in child class");
};

// In the 'Synchronized' state, there is no pending operation that the client
// has sent to the server.
function Synchronized() {}
Client.Synchronized = Synchronized;

Synchronized.prototype.applyClient = function (client, operation) {
  // 当用户进行编辑时 将操作发送到服务器 并且换状态
  // When the user makes an edit, send the operation to the server and
  // switch to the 'AwaitingConfirm' state
  client.sendOperation(client.revision, operation);
  return new AwaitingConfirm(operation);
};

Synchronized.prototype.applyServer = function (client, operation) {
  // 接收服务器操作 应用到当前文档
  // When we receive a new operation from the server, the operation can be
  // simply applied to the current document
  client.applyOperation(operation);
  return this;
};

Synchronized.prototype.serverAck = function (client) {
  throw new Error("There is no pending operation.");
};

// Nothing to do because the latest server state and client state are the same.
Synchronized.prototype.transformSelection = function (x) {
  return x;
};

// Singleton
var synchronized_ = new Synchronized();

// In the 'AwaitingConfirm' state, there's one operation the client has sent
// to the server and is still waiting for an acknowledgement.
// 客户端已经发送到服务器一个操作了 并更改操作
function AwaitingConfirm(outstanding) {
  // Save the pending operation
  this.outstanding = outstanding;
}
Client.AwaitingConfirm = AwaitingConfirm;

AwaitingConfirm.prototype.applyClient = function (client, operation) {
  // When the user makes an edit, don't send the operation immediately,
  // instead switch to 'AwaitingWithBuffer' state
  // 用户编辑时不立即发送 切换状态
  return new AwaitingWithBuffer(this.outstanding, operation);
};

AwaitingConfirm.prototype.applyServer = function (client, operation) {
  // This is another client's operation. Visualization:
  //
  //                   /\
  // this.outstanding /  \ operation
  //                 /    \
  //                 \    /
  //  pair[1]         \  / pair[0] (new outstanding)
  //  (can be applied  \/
  //  to the client's
  //  current document)
  var pair = operation.constructor.transform(this.outstanding, operation);
  client.applyOperation(pair[1]);
  return new AwaitingConfirm(pair[0]);
};

AwaitingConfirm.prototype.serverAck = function (client) {
  // The client's operation has been acknowledged
  // => switch to synchronized state
  // 客户端操作已经确认 切换到同步状态
  return synchronized_;
};

AwaitingConfirm.prototype.transformSelection = function (selection) {
  return selection.transform(this.outstanding);
};

AwaitingConfirm.prototype.resend = function (client) {
  // The confirm didn't come because the client was disconnected.
  // Now that it has reconnected, we resend the outstanding operation.
  // 重新连接 重新发送操作
  client.sendOperation(client.revision, this.outstanding);
};

// In the 'AwaitingWithBuffer' state, the client is waiting for an operation
// to be acknowledged by the server while buffering the edits the user makes
// 客户端正在等待一个操作被服务器确认 同时缓冲用户所有做的编辑
function AwaitingWithBuffer(outstanding, buffer) {
  // Save the pending operation and the user's edits since then
  // 保存挂起的操作和用户之后的编辑
  this.outstanding = outstanding;
  this.buffer = buffer;
}
Client.AwaitingWithBuffer = AwaitingWithBuffer;

AwaitingWithBuffer.prototype.applyClient = function (client, operation) {
  // Compose the user's changes onto the buffer
  // 将用户的更改写入缓冲区
  var newBuffer = this.buffer.compose(operation);
  return new AwaitingWithBuffer(this.outstanding, newBuffer);
};

AwaitingWithBuffer.prototype.applyServer = function (client, operation) {
  // Operation comes from another client
  //
  //                       /\
  //     this.outstanding /  \ operation
  //                     /    \
  //                    /\    /
  //       this.buffer /  \* / pair1[0] (new outstanding)
  //                  /    \/
  //                  \    /
  //          pair2[1] \  / pair2[0] (new buffer)
  // the transformed    \/
  // operation -- can
  // be applied to the
  // client's current
  // document
  //
  // * pair1[1]
  var transform = operation.constructor.transform;
  var pair1 = transform(this.outstanding, operation);
  var pair2 = transform(this.buffer, pair1[1]);
  client.applyOperation(pair2[1]);
  return new AwaitingWithBuffer(pair1[0], pair2[0]);
};

AwaitingWithBuffer.prototype.serverAck = function (client) {
  // The pending operation has been acknowledged
  // => send buffer
  // 等待操作已被确认 发送缓冲区
  client.sendOperation(client.revision, this.buffer);
  return new AwaitingConfirm(this.buffer);
};

AwaitingWithBuffer.prototype.transformSelection = function (selection) {
  return selection.transform(this.outstanding).transform(this.buffer);
};

AwaitingWithBuffer.prototype.resend = function (client) {
  // The confirm didn't come because the client was disconnected.
  // Now that it has reconnected, we resend the outstanding operation.
  client.sendOperation(client.revision, this.outstanding);
};

export { Client };
