import "./UserAvator.less";

export interface IUserAvatorProps {
  name: string;
  background: string;
  style?: React.CSSProperties;
  className?: string;
}

const UserAvator: React.FC<IUserAvatorProps> = (props) => {
  const { name, background, style, className } = props;
  return (
    <div
      className={`${className} user-default`}
      style={{ background: `${background}`, ...style }}>
      {name}
    </div>
  );
};

export default UserAvator;
