import { useEffect, useState } from "react";
import { Upload, Message, Image } from "@arco-design/web-react";
import { excel_icon } from "@/assets/creationscriptimage";
import * as XLSX from "xlsx";
import "./index.less";
interface IFilesUpload {
  getJson: (opt: any) => void;
  visible: boolean;
}
const FilesUpload: React.FC<IFilesUpload> = ({ getJson, visible }) => {
  const [moveStatus, setMoveStatus] = useState(false);
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    const dragTarget = document.querySelector(".modal-excel");
    const handleDragOver = (event) => {
      event.preventDefault();
      dragTarget.classList.add("modal-excel-dragover");
    };
    const handleDragLeave = () => {
      dragTarget.classList.remove("modal-excel-dragover");
    };
    dragTarget.addEventListener("dragover", handleDragOver);
    dragTarget.addEventListener("dragleave", handleDragLeave);
    return () => {
      dragTarget.removeEventListener("dragover", handleDragOver);
      dragTarget.removeEventListener("dragleave", handleDragLeave);
    };
  }, []);
  const handleDrop = (file) => {
    // 检查文件类型
    if (
      !file.type.match(
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ) &&
      !file.type.match("application/vnd.ms-excel")
    ) {
      Message.error(`${file.name} 不是有效的Excel文件`);
      return false;
    }
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      // ,{ header: 1 }//确保包含表头
      const json: any = XLSX.utils.sheet_to_json(worksheet);
      setFileList([{ uid: "1", name: file.name }]);
      getJson(json);
      Message.success(`${file.name} 文件上传并解析成功`);
    };
    reader.readAsBinaryString(file);
    return true;
  };
  useEffect(() => {
    if (!visible) {
      setFileList([]);
      getJson([]);
    }
  }, [visible]);
  const mouseMovement = (type: boolean) => {
    return type ? (
      <p>释放文件并开始上传</p>
    ) : (
      <p>
        将文件拖拽到虚线区域上传或&emsp;<span>点击上传</span>
      </p>
    );
  };
  return (
    <Upload
      drag
      limit={1}
      multiple={false}
      fileList={fileList}
      accept={".xlsx, .xls"}
      action="/"
      onDragOver={(e) => {
        setMoveStatus(true);
      }}
      onDragLeave={(e) => {
        setMoveStatus(false);
      }}
      beforeUpload={handleDrop}
      onRemove={(e) => {
        setFileList([]);
      }}
      tip="文件最大支持200MB">
      <div className="modal-excel">
        <div className="excel-icon">
          <Image width={68} preview={false} src={excel_icon} />
        </div>
        <div className="excel-lable">{mouseMovement(moveStatus)}</div>
        <p>文件最大支持200MB</p>
      </div>
    </Upload>
  );
};

export default FilesUpload;
