import React, { useEffect, useState } from "react";
import Footer from "@/components/UI/Footer";
import "@arco-design/web-react/dist/css/arco.css";
import LoginForm from "./form";
import LoginBanner from "./banner";
import "./style/style.less";
import loginimg from "@/assets/Logo/logo.png";
function Login() {
  const [count, setCount] = useState(0);
  const [submitTime] = useState("2020-10-28 16:31");
  useEffect(() => {
    window.localStorage.clear();
  }, []);
  return (
    <div className="container">
      <div
        className="version"
        onClick={() => {
          setCount(count + 1);
        }}>
        {count > 10 ? submitTime : ""}
      </div>

      <div className="logo">
        {/* <Logo /> */}
        {/* <div className="text">Arco Design Pro</div> */}
        {/* 轮播图 top left 位置 可填充  */}
        <img src={loginimg} className="login_contel_itemIMG" alt="" />
      </div>
      <div className="banner">
        <div className="inner">
          <LoginBanner />
        </div>
      </div>
      <div className="content">
        <div className="inner">
          <LoginForm />
        </div>
        <div className="footer">
          <Footer />
        </div>
      </div>
    </div>
  );
}
Login.displayName = "LoginPage";

export default Login;
