import React from "react";
interface VideBoxProps {
  solidleft: number;
  solidwidth: number;
}
import "./video.less";
import "../../index.less";
const Videobox: React.FC<VideBoxProps> = (props) => {
  const { solidleft, solidwidth } = props;
  return (
    <div
      className="propsem-video-prost"
      style={{
        position: "absolute",
        // left: `calc(3% + ${solidleft}%)`,
        // width: `calc((100% - 6%) * ${solidwidth}%)`,
        left: `calc(${solidleft}%)`,
        width: `calc(${solidwidth}%)`,
      }}>
      <div className="box-contel">
        <div className="boxa"></div>
        <div className="boxb"></div>
        <div className="boxc"></div>
      </div>
      <div className="box-footer">
        <div className="box-footer-a">
          <div className="box-footer-left"></div>
          <div className="box-footer-left-bottom"></div>
        </div>
        <div className="box-footer-b">
          <div className="box-footer-right"></div>
          <div className="box-footer-right-bottoma"></div>
        </div>
      </div>
    </div>
  );
};

export default Videobox;
