import React, {
  useEffect,
  useRef,
  useState,
  ReactNode,
  useCallback,
} from "react";
import "./video.less";
// import "../../index.less";
import { Slider } from "@arco-design/web-react";
import { IconSound, IconMute } from "@arco-design/web-react/icon";
import vodeoplayback from "@/assets/videoparsing/vodeoplayback.png";
import videofullscreen from "@/assets/videoparsing/videoFullSCREEN.png";
import Videobox from "./videoBox";
interface VideoProps {
  /***
   * 视频地址链接
   */
  src?: string; //
  /**
   *  视频 开始位置
   */
  state?: number;
  /***
   * 视频播放时间
   */
  stateTime?: number;
  /***
   * 预览模式 hover时 自动 播放视频
   */
  Preview?: boolean;
  /***
   * 预览模式中的首屏图片 需配合预览模式使用
   */
  imgs?: string;
  /***
   * 插槽 需设置 position: "absolute"和 z-index:<2
   */
  nodeslort?: ReactNode;
  /***
   *style樣式
   */
  style?: React.CSSProperties;
}

const CustomVideo: React.FC<VideoProps> = (props) => {
  const { src, state, Preview, stateTime, imgs, nodeslort, style } = props;
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(60);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  const videoContainerRef = useRef<HTMLDivElement>(null);
  const [duration, setDuration] = useState(0);
  const [mute, setMute] = useState(false);
  const [Time, setTime] = useState(null);
  const countTimedefalue = "0.00";
  const [Timecounter, setTimecounter] = useState(countTimedefalue);
  const [solid, setsolid] = useState(false);
  const [solidwidth, setsolidwidth] = useState(0);
  const [solidleft, setsolidleft] = useState(0);
  const ImgSrcDom = useRef<HTMLImageElement>();
  const ImgIconDom = useRef<HTMLDivElement>();
  const Refviode = () => {
    if (videoRef && videoRef.current) {
      return videoRef;
    }
  };

  const togglePlay = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const toggleFullScreenmain = () => {
    if (isFullScreen) {
      document.exitFullscreen();
    } else {
      videoContainerRef.current.requestFullscreen();
    }
    setIsFullScreen(!isFullScreen);
  };

  const Timecount = (duration, currentTime) => {
    if (isNaN(duration)) {
      duration = 0;
    }
    return Number(Number(currentTime / duration) * 100);
  };

  const toMuteAndVolumeGetVolume = (mute: boolean, volume: number) => {
    return mute ? 0 : volume;
  };

  const handelexitFullScreen = () => {
    if (document.fullscreenElement) {
      setIsFullScreen(true);
    } else {
      setIsFullScreen(false);
    }
  };

  const VideoTimeCount = () => {
    const VideoTmie = document.querySelector(
      ".custom-video",
    ) as HTMLMediaElement;

    const result = VideoTmie.duration;
    const TImeformatcount = TimeFormat(Math.ceil(result));

    setTime(TImeformatcount);
  };

  const TimeFormat = (e: number) => {
    // if (e < 10) {
    //   return `0:0${e}`;
    // } else if (10 <= e && e < 60) {
    //   return `0:${e}`;
    // } else if (60 <= e && e < 3600) {
    //   const min = Math.ceil(e / 60);
    //   const yushu = e % 60;
    //   return `${min}:${yushu >= 10 ? yushu : "0" + yushu}`;
    // }
    const minutes = Math.floor(e / 60);
    const seconds = Math.ceil(e % 60);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const onMouseEnterimgsrc = () => {
    if (ImgSrcDom && ImgIconDom && ImgSrcDom.current && ImgIconDom.current) {
      ImgIconDom.current.classList.add("video-iconimgsrc-hover");
      ImgSrcDom.current.classList.add("video-iconimgsrc-hover");
      videoRef.current.currentTime = 0;
      setTimeout(() => {
        videoRef.current.play();
      }, 300);
      setTimeout(() => {
        ImgSrcDom.current.classList.add("video-iconimgsrc-hover-display");
      }, 310);
    }
  };
  function throttle(func, wait) {
    let timeout = null;
    return function (...args) {
      if (!timeout) {
        timeout = setTimeout(() => {
          func.apply(this, args);
          timeout = null;
        }, wait);
      }
    };
  }

  const throttledOnChange = throttle((e) => {
    videoRef.current.currentTime =
      ((e as number) / 100) * videoRef.current.duration;
  }, 200);

  const onMouseLeaveimgsrc = () => {
    if (ImgSrcDom && ImgIconDom && ImgSrcDom.current && ImgIconDom.current) {
      ImgIconDom.current.classList.remove("video-iconimgsrc-hover");
      ImgSrcDom.current.classList.remove("video-iconimgsrc-hover");
      ImgSrcDom.current.classList.remove("video-iconimgsrc-hover-display");
    }
  };

  useEffect(() => {
    const VideoTmie = document.querySelector(
      ".custom-video",
    ) as HTMLMediaElement;
    const updateCurrentTimeDisplay = () => {
      const currentTime = Math.ceil(VideoTmie.currentTime);

      const TImeformatcount = TimeFormat(currentTime);
      setTimecounter(TImeformatcount);
    };
    VideoTmie.addEventListener("loadedmetadata", VideoTimeCount);
    VideoTmie.addEventListener("timeupdate", updateCurrentTimeDisplay);
    document.addEventListener("fullscreenchange", handelexitFullScreen);
    return () => {
      document.removeEventListener("fullscreenchange", handelexitFullScreen);
      VideoTmie.removeEventListener("timeupdate", updateCurrentTimeDisplay);
      VideoTmie.removeEventListener("loadedmetadata", VideoTimeCount);
    };
  }, []);

  useEffect(() => {
    console.log(state, stateTime);
    if (!state) return;
    videoRef.current.currentTime = state / 1000;

    let timer = null;
    if (
      state !== null &&
      stateTime !== null &&
      (state != 0 || stateTime != 0) &&
      !Preview
    ) {
      videoRef.current.play().then(() => {
        setsolid(true);
        const lef = (state / 1000 / videoRef.current.duration) * 100;
        const wid = (stateTime / 1000 / videoRef.current.duration) * 100;
        setsolidwidth(wid);
        setsolidleft(lef);
        timer = setInterval(() => {
          if (stateTime + state <= videoRef.current.currentTime * 1000) {
            clearInterval(timer);
            videoRef.current.pause();
          }
        }, 10);
      });
    } else {
      setsolid(false);
      videoRef.current.pause();
    }

    return () => {
      clearInterval(timer);
    };
  }, [state, stateTime]);

  return (
    <div
      className="custom-video-container"
      onMouseLeave={() => {
        if (imgs && Preview) {
          videoRef.current.pause();
          onMouseLeaveimgsrc();
        }
      }}
      style={{
        width: 300,
        height: 480,
        ...style,
      }}>
      <div
        ref={videoContainerRef}
        className={`${isFullScreen ? "full-screen" : "default"} video-wrapper`}>
        {nodeslort}
        <video
          src={src ? src : "https://www.w3schools.com/html/mov_bbb.mp4"}
          className="custom-video"
          controls={false}
          autoPlay={false}
          loop={false}
          muted={mute}
          ref={videoRef}
          onPlay={() => setIsPlaying(true)}
          onPause={() => setIsPlaying(false)}
          // onVolumeChange={(e) => setVolume((e.target as HTMLVideoElement).volume)}
          onTimeUpdate={(e) => {
            if (e.target) {
              let data = Timecount(
                (e.target as HTMLVideoElement).duration,
                (e.target as HTMLVideoElement).currentTime,
              );
              if (isNaN(data)) {
                data = 0;
                setDuration(0);
              } else {
                setDuration(data);
              }
            }
          }}
          onClick={() => {
            togglePlay();
          }}>
          您的浏览器不支持 HTML5 视频。
        </video>

        {imgs && Preview ? (
          <img
            ref={ImgSrcDom}
            className="video-imgsrc-hover"
            src={imgs}
            alt=""
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              zIndex: `${!isPlaying ? "2" : "0"}`,
              width: "100%",
              height: "100%",
            }}
          />
        ) : (
          <></>
        )}

        <div
          onClick={togglePlay}
          className="video-icon-Playing"
          ref={ImgIconDom}>
          {isPlaying ? (
            ""
          ) : (
            <img
              onMouseEnter={() => {
                if (imgs && Preview) {
                  onMouseEnterimgsrc();
                }
              }}
              src={vodeoplayback}
              alt=""
            />
          )}
        </div>
        {!Preview && (
          <div
            className={
              isFullScreen
                ? "custom-video-controls-S"
                : "custom-video-controls-C"
            }>
            <div className="video-controlsprogress-slide">
              <div
                className="video-progress-slider"
                style={{ position: "relative" }}>
                <Slider
                  tooltipVisible={false}
                  value={duration}
                  onChange={(e) => {
                    throttledOnChange(e);
                  }}
                  style={{ width: 200 }}
                />
                {solid && (
                  <Videobox
                    solidleft={solidleft}
                    solidwidth={solidwidth}></Videobox>
                )}
              </div>
            </div>

            <div className="video-contel-Fluss">
              <span className="video-Fluess-Time">
                {Timecounter}/{Time}
              </span>
              <div className="slider-volume-body">
                <div className="slider-volume">
                  <div className="video-slider-hover">
                    {volume && !mute ? (
                      <IconSound style={{ fontSize: 16, color: "#FFFFFF" }} />
                    ) : (
                      <IconMute style={{ fontSize: 16, color: "#FFFFFF" }} />
                    )}
                    <div className="video-silder-wrapper">
                      <div className="video-slider-hover-contel">
                        <span className="volume-contaienr">{volume}</span>
                        <Slider
                          tooltipVisible={false}
                          value={toMuteAndVolumeGetVolume(mute, volume)}
                          onChange={(e) => {
                            setVolume(e as number);
                            videoRef.current.volume = (e as number) / 100;
                          }}
                          vertical
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  onClick={toggleFullScreenmain}
                  className="Fluess-Icon-fluess">
                  {isFullScreen ? (
                    <img src={videofullscreen} alt="" />
                  ) : (
                    <img src={videofullscreen} alt="" />
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomVideo;
