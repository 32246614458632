import {
  Form,
  Button,
  Space,
  Input,
  Message,
  Divider,
} from "@arco-design/web-react";
import React, { useEffect, useRef, useState } from "react";
import "./style/style.less";
import returnLogin from "@/assets/iconimage/returnlogin.png";
import { observer, inject } from "mobx-react";
import { useNavigate } from "react-router-dom";
type Psswordone = {
  color: string;
  nub: number;
  numbers: number[] | undefined;
};
const FormItem = Form.Item;
const Forgotpassword = (props: any) => {
  const { login } = props;
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const FormreGist = useRef(form);
  const [twopasswordmise, settwopasswordmise] = useState(null);
  const [psswordone, setpsswordone] = useState<Psswordone>({
    color: "",
    nub: -1,
    numbers: undefined,
  });
  const [startactivopassrd, setstartactivopassrd] = useState(false);
  const [loading, setLoading] = useState(false);

  let countdownInterval = null;

  const handelopponclick = async () => {
    await clearInterval(countdownInterval);
    countdownInterval = null;
    props.handeonclick(true);
  };

  const handeoppactiveloginpas = async (datalist) => {
    await login.reset_password_to(datalist).then((res) => {
      if (res == "OK") {
        setstartactivopassrd(true);
        let count = 5;
        countdownInterval = setInterval(() => {
          if (count == 0) {
            clearInterval(countdownInterval);
            handelopponclick();
          } else {
            const et = document.querySelector(
              ".returnloginbodyjopj",
            ) as HTMLDivElement;
            if (et) {
              et.innerHTML = `${count - 1}秒后返回登录`;
            }
            count--;
          }
        }, 1000);
      } else {
        Message.info(res);
      }
    });
  };

  async function validatePhoneNumber(value, callback) {
    const element = document.getElementById("opa") as HTMLButtonElement;
    const regex = /^1(3|4|5|6|7|8|9)\d{9}$/;
    if (!value) {
      element.disabled = true;
      element.style.color = "#666666";
      callback(<div>手机号不能为空！</div>);
    } else if (!regex.test(value)) {
      callback(<div>手机号格式不正确,请重新输入</div>);
      element.disabled = true;
      element.style.color = "#666666";
      element.style.cursor = "default";
    } else {
      await login.exist_phone_number_to(FormreGist).then((res) => {
        if (res == "ok") {
          element.disabled = true;
          element.style.color = "#666666";
          element.style.cursor = "default";
          callback(<div>当前手机号不存在,可直接注册</div>);
        } else if (res == "no") {
          element.disabled = false;
          element.style.color = "#165DFF";
          element.style.cursor = "pointer";
          callback();
        }
      });
    }
  }

  async function validateCaptcha(value, callback) {
    if (!value) {
      callback(<div>验证码不能为空</div>);
    } else {
      await login.verifyCode_to(FormreGist).then((res) => {
        console.log(res);
        if (res == "ok") {
          callback();
        } else if (res == "no") {
          callback(<div>验证码错误</div>);
        }
      });
    }
  }

  function validatePassword(value, callback) {
    const elementnode = document.querySelector(".passQDess") as HTMLElement;
    if (!value) {
      if (elementnode) {
        elementnode.style.display = "none";
      }
      PasswordStrength();
      callback(<div>密码不能为空</div>);
    } else if (!/^.{6,}$/.test(value)) {
      if (elementnode) {
        elementnode.style.display = "none";
      }
      PasswordStrength();
      callback(
        <div>请输入6-12个字符，支持数字、大小写字母和符号至少包含一种</div>,
      );
    } else if (twopasswordmise == value) {
      if (elementnode) {
        elementnode.style.display = "none";
      }
      callback(<div>设置的新密码不可与上次一致</div>);
      PasswordStrength();
    } else {
      if (elementnode) {
        elementnode.style.display = "none";
      }
      callback();
      PasswordStrength();
    }
  }

  const validatePasswordConfirm = (value, callback) => {
    const { getFieldValue } = FormreGist.current;
    const password = getFieldValue("password");
    const popp = getFieldValue("popp");
    if (!popp) {
      callback(<div>密码不能为空</div>);
    } else if (!/^.{6,}$/.test(popp)) {
      callback(
        <div>请输入6-12个字符，支持数字、大小写字母和符号至少包含一种</div>,
      );
    } else if (!password) {
      callback(<div>请设置密码，并再次输入密码</div>);
    } else if (password && popp !== password) {
      callback(<div>两次密码输入不一致，请重新输入</div>);
    } else {
      callback();
    }
  };

  const PasswordStrength = () => {
    const { getFieldValue } = FormreGist.current;
    const password = getFieldValue("password");
    let strength = -1;
    if (password && password.length > 5) {
      if (/[0-9]/.test(password)) strength += 1;
      if (password.length >= 8) {
        if (/[a-z]/.test(password)) strength += 1;
        if (/[A-Z]/.test(password)) strength += 1;
        if (/\W/.test(password)) strength += 1;
      }
    }
    const level = Math.min(strength, 3);
    let levelop;
    if (level == -1 || level == 0) {
      levelop = 1;
    } else {
      levelop = level;
    }
    const color = ["red", "#FFA5A5", "#faad14", "#A7CBFF", "#A7CBFF"][levelop];
    const numbers = Array.from({ length: levelop }, (_, index) => index);
    setpsswordone({ color, nub: level, numbers: numbers });
  };
  useEffect(() => {
    const element = document.getElementById("opa") as HTMLButtonElement;
    element.disabled = true;
    element.style.color = "#666666";
    element.style.backgroundColor = "transparent";
    if (element) {
      const handleClick = (element) => {
        login.sendCode_to(FormreGist);
        const et = element;
        et.target.disabled = true;
        et.target.style.color = "#666666";
        let count = 10;
        const regex = /^1(3|4|5|6|7|8|9)\d{9}$/;
        const countarr = setInterval(() => {
          et.target.style.cursor = "default";
          et.target.innerHTML = `${count - 1}秒后重发`;
          count--;
          if (count <= 0) {
            const { getFieldValue } = FormreGist.current;
            const phone_number = getFieldValue("phone_number");
            if (!regex.test(phone_number)) {
              et.target.innerHTML = `重新发送`;
              et.target.style.color = "#666666";
              clearInterval(countarr);
              et.target.disabled = true;
              et.target.style.cursor = "default";
            } else {
              et.target.innerHTML = `重新发送`;
              et.target.style.color = "#165DFF ";
              clearInterval(countarr);
              et.target.disabled = false;
              et.target.style.cursor = "pointer";
            }
          }
        }, 1000);
      };
      element.addEventListener("click", handleClick);
      return () => {
        element.removeEventListener("click", handleClick);
        clearInterval(countdownInterval);
      };
    }
  }, []);

  return (
    <div>
      {!startactivopassrd ? (
        <div className="toppoab">
          <span className="login-form-active" id="regist">
            找回密码
          </span>
          <Form id="regist" ref={FormreGist} autoComplete="off">
            <FormItem
              field="phone_number"
              rules={[
                { validator: validatePhoneNumber, validateTrigger: "onBlur" },
                {},
              ]}>
              <Input
                className="form-contorinput"
                allowClear={true}
                placeholder="请输入手机号"
              />
            </FormItem>
            <FormItem
              field="code"
              rules={[
                { validator: validateCaptcha, validateTrigger: "onBlur" },
              ]}>
              <Input
                placeholder="请输入验证码"
                suffix={
                  <div className="inputyzmbody">
                    <div id="inputyzmi"></div>
                    <button id="opa">获取验证码</button>
                  </div>
                }
              />
            </FormItem>
            <FormItem
              className="registformpssword"
              field="password"
              rules={[
                { validator: validatePassword, validateTrigger: ["onBlur"] },
              ]}>
              <Input.Password defaultValue="password" placeholder="设置密码" />
            </FormItem>
            {psswordone.nub != -1 && (
              <div className="passQD">
                <div className="passQDleft">
                  密码强度：
                  {psswordone.numbers &&
                    psswordone.numbers.length &&
                    psswordone.numbers.map((item, index) => {
                      return (
                        <div key={index} className="passQDleftdiv">
                          <p
                            style={{ backgroundColor: psswordone.color }}
                            className="psswordonecolor"></p>
                          <p
                            style={{ backgroundColor: psswordone.color }}
                            className="psswordonecolor"></p>
                        </div>
                      );
                    })}
                </div>
                <p className="lpassQDright">
                  {psswordone.nub === 1 || psswordone.nub === 0
                    ? "弱"
                    : psswordone.nub === 2
                      ? "中"
                      : "强"}
                </p>
              </div>
            )}
            <div className="passQDess">
              6-12个字符，支持数字、大小写字母和符号至少包含一种
            </div>

            <FormItem
              field="popp"
              rules={[
                {
                  validator: validatePasswordConfirm,
                  validateTrigger: "onBlur",
                },
              ]}>
              <Input.Password
                defaultValue="password"
                placeholder="再次输入密码"
              />
            </FormItem>
            <Space direction="vertical" style={{ marginTop: "22px" }}>
              <Button
                type="primary"
                long
                onClick={async () => {
                  if (FormreGist.current) {
                    try {
                      const value = await FormreGist.current.validate();
                      handeoppactiveloginpas({ ...value, ...psswordone });
                    } catch (_) {
                      console.error(_);
                    }
                  }
                }}
                loading={loading}
                className="styleInputbutton">
                提交
              </Button>
            </Space>
          </Form>
          {/* <div
            className="Fooass_wordtext"
            onClick={() => {
              handelopponclick();
            }}>
            去登陆
          </div> */}
          <div className="bttomactivehov" style={{ bottom: "-100px" }}>
            <div className="bottmbodyheader" style={{ cursor: "pointer" }}>
              <Divider orientation="center">
                <span
                  // style={{ color: "#417AFF" }}
                  onClick={() => {
                    handelopponclick();
                  }}>
                  返回登录
                </span>
              </Divider>
            </div>
          </div>
        </div>
      ) : (
        <div className="retunrloginbody">
          <div className="returnloginbodymain">
            <div className="retunlogintopheader">
              <img src={returnLogin} alt="" />
              <p>重置密码成功</p>
            </div>
            <div className="returnloginbodyjopj">5秒后返回登录</div>
            <div>
              <Button
                type="primary"
                className="subminglogin"
                long
                onClick={() => {
                  handelopponclick();
                }}>
                去登录
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default inject("login")(observer(Forgotpassword));
