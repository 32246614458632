import { useEffect, useState } from "react";
import resolution, { SizeEnum } from "./config";
import ResolutionBox from "./ResolutionBox/ResolutionBox";
import "./Resolution.less";

interface IResolution {
  onChange?: (e) => void;
  value?: string;
}

const Resolution: React.FC<IResolution> = (props) => {
  const { onChange, value } = props;
  const [activeindex, setActiveIndex] = useState(1000);
  const toValueGetIndex = (name: string) => {
    const index = resolution.findIndex((item) => item.name === name);
    return index > -1 ? index : 1000;
  };

  useEffect(() => {
    setActiveIndex(toValueGetIndex(value));
  }, [value]);
  return (
    <>
      <ul className="resolution-wrapper">
        {resolution.map((item, index) => {
          return (
            <li
              key={item.name + index}
              onClick={() => {
                setActiveIndex(index);
                if (onChange) {
                  onChange(item.name);
                }
              }}
              className={`${activeindex === index ? "active" : ""}`}>
              {item.name !== "customized" ? (
                <ResolutionBox size={item.title as SizeEnum} />
              ) : (
                <></>
              )}
              {item.title}
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default Resolution;
