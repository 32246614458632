/* eslint-disable no-case-declarations */
import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Button,
  Image,
  Avatar,
  Dropdown,
  Message,
  Input,
  Tooltip,
  Modal,
} from "@arco-design/web-react";
import Modals from "@/components/UI/Modals";
import {
  add_column_icon,
  add_grouping_icon,
  import_icon,
  input_icon,
  save_w_icon,
  download_icon,
  inscript_icon,
  suspend_ai_icon,
} from "@/assets/creationscriptimage";
import { IconClose } from "@arco-design/web-react/icon";
import { observer, inject } from "mobx-react";
import { TextOperation } from "@/lib/text-operation";
import { diff_match_patch } from "@/lib/diff-match-patch";
import { v4 as uuidv4 } from "uuid";
import { Client } from "@/lib/client";
import Intelligent from "@/components/business/Intelligent";
import Assistant from "@/components/business/Assistant";
import {
  getScript,
  getShare_doc,
} from "@/services/creativeScript/creationStores";
import ContentHeader from "@/components/UI/ContentHeader";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import FilesUpload from "./component/FilesUpload";
import DragDrop from "./component/DragDrop";
import useData from "./useData";
import _ from "lodash";
import "./index.less";
import Login from "@/stores/login";
import {
  editScript,
  getTeamDetail,
} from "@/services/creativeScript/explosiveScript";
import { toJS } from "mobx";
import creationStores from "@/stores/creationScriptStore";
import { getTokenToLocalStorage } from "@/utils/token";
import dayjs from "dayjs";
import { filmDditingIcon } from "@/assets/scriptimage";
import User from "@/stores/userStore";
import CreateEngineeringModal from "../CreationSpace/CreativeSpace/CreativeContainer/components/Engineering/CreatEngineeringModal/CreateEngineeringModal";
import { createEngineering } from "@/services/createSpace/engineering";
import TableAnchor from "./component/TableAnchor";
import useMixCutDetailMsg from "@/useStore/mixCutDetail/useMixCutDetailMsg";
import {
  addCustomScriptRefenceCount,
  toIdGetCustomScript,
} from "@/services/createSpace/script";
import useParseScript from "@/useStore/mixCutDetail/useParseScript";
import { saveEngineeringContent } from "@/services/mixCutDetail/mixCutDetail";
const AvatarGroup = Avatar.Group;
const diff = new diff_match_patch();
// ，将两个文档的差异转换为TextOperation操作
const patchToOperation = (oldDoc, newDoc) => {
  const operation = new TextOperation();
  diff.diff_main(oldDoc, newDoc).forEach((diff) => {
    switch (diff[0]) {
      case 0:
        operation.retain(diff[1].length);
        break;
      case 1:
        operation.insert(diff[1]);
        break;
      case -1:
        operation.delete(diff[1].length);
        break;
      default:
        break;
    }
  });
  return operation;
};
const delay = (callback, time) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      callback();
      resolve(1);
    }, time);
  });
};
export default CreationScript;
let ws;
function CreationScript() {
  const locations = useLocation();
  const [visibleModal, setVisibleModal] = useState(false);
  const { handleChangeMixCutDetailMsgEngineer } = useMixCutDetailMsg();
  const { parseCutsomScript } = useParseScript();
  // 解析导入脚本文件的数据
  const [createModalVisible, setCreateModalVisible] = useState<boolean>(false);
  const [getJson, setGetJson] = useState([]);
  const data = useRef<any>();
  const client = useRef(null);
  const userId = useRef<any>(1);
  const clientId = useRef<any>(uuidv4());
  const [creationName, setCreationName] = useState("");
  const creationNameRef = useRef(null);
  const [userList, setUserList] = useState([]);
  const [updateTime, setUpdateTime] = useState<any>(
    new Date(dayjs().format("YYYY-MM-DD HH:mm")).getTime() / 1000,
  );
  const [isEditName, setIsEditName] = useState<boolean>(false);
  const editNameRef = useRef(null);
  const countRef = useRef(0);
  const params = useParams();
  const token = getTokenToLocalStorage();
  const [outInfo, setOutInfo] = useState<string>("");
  const navigate = useNavigate();
  const [tablePosition, setTablePosition] = useState({
    row: 0,
    col: 0,
  });
  const { pureParseCutsomScript } = useParseScript();
  const [cursorInfo, setCursorInfo] = useState([]);
  const dataSendFUn = useCallback(
    _.debounce(() => {
      if (countRef.current) {
        client.current?.performOperation(
          client.current.document,
          JSON.stringify(creationStores.dataSource),
        );
      }
      countRef.current++;
      data.current = JSON.stringify(creationStores.dataSource);
    }, 500),
    [],
  );

  const sendCursor = useCallback(
    _.debounce((info) => {
      ws.send(
        JSON.stringify({
          method: 1,
          body: JSON.stringify(info),
        }),
      );
    }, 500),
    [],
  );
  const {
    columns,
    menuData,
    defaultInitialData,
    insertAndUpdateKeys,
    selectStatus,
    setSelectStatus,
    suspendAi,
    setSuspendAi,
    dropList,
    editorHandler,
    smartStatus,
    sendSmartStatus,
    setItelligentIsExecution,
    selectInfo,
    setSendSmartStatus,
  } = useData({
    delay,
    creationStores,
    dataSendFUn,
    sendCursor,
  });

  const closeModal = () => {
    setVisibleModal(false);
  };
  // 导入脚本保存
  const importScript = () => {
    // ...toJS(creationStores.dataSource)
    const newData = [];
    //  设置一个计数器用于生成唯一的key值
    // 如果c数组已有数据，则keyCounter初始化为c中最后一个元素的key加1，否则初始化为1
    let keyCounter =
      newData.length > 0 ? Math.max(...newData.map((item) => item.key)) + 1 : 1;
    // 转换数组a到数组c的格式
    const keyMapping = {};
    const cls = columns;
    cls.forEach((item) => {
      keyMapping[item.title] = item.dataIndex;
    });

    getJson.forEach((item) => {
      const newObj = { key: keyCounter++ }; // 初始化新对象并设置key值
      for (const key in item) {
        if (keyMapping[key]) {
          if (key === "台词文案") {
            // 特殊处理台词文案
            newObj[keyMapping[key]] = [
              {
                type: "listItem",
                content: [
                  {
                    type: "paragraph",
                    text: item[key],
                  },
                ],
              },
            ];
          } else {
            newObj[keyMapping[key]] = [
              {
                type: "paragraph",
                content: [
                  {
                    type: "text",
                    text: item[key],
                  },
                ],
              },
            ];
          }
        }
      }
      newData.push(newObj); // 将新对象添加到数组c中
    });
    if (newData.length > 15) {
      Message.warning("分镜组个数最多为15个");
    }
    creationStores.delData(newData.slice(0, 15));
    closeModal();
    dataSendFUn();
  };
  const editScriptFun = async (type) => {
    const items = {
      content: JSON.stringify(toJS(creationStores.dataSource)) || "",
      title: creationName || "",
      id: params.id || locations.state.id,
    };
    if (type == "title") {
      items.content = "";
      creationNameRef.current = creationName;
    } else {
      items.title = "";
    }
    const res = await editScript(items);
    if (res?.code == 0) {
      if (type != "title") {
        Message.success("保存成功");
      }
    } else {
      Message.error(res);
    }
  };

  // 智能文案操作
  const handleClickInterlligent = (opt) => {
    const edit = editorHandler.current[selectInfo.index].editor;

    switch (opt.type) {
      case "change":
        if (
          sendSmartStatus ==
          edit
            .getText()
            .replace(/\n\n\n\n/g, "\n")
            .slice(1)
        ) {
          edit.commands.focus("all");
          edit.commands.insertContent(opt.text);
        } else {
          edit.commands.insertContentAt(selectInfo.range, opt.text, {
            updateSelection: true,
            parseOptions: {
              preserveWhitespace: "full",
            },
          });
        }
        Message.success("替换成功");
        break;
      case "copy":
        navigator.clipboard.writeText(opt.text);
        Message.success("复制成功");
        break;
      case "splice":
        edit.commands.insertContentAt(selectInfo.range, opt.text, {
          updateSelection: true,
          parseOptions: {
            preserveWhitespace: "full",
          },
        });
        Message.success("添加成功");
        break;
      default:
        break;
    }
    dataSendFUn();
  };
  // 定义一个打印函数，用于调试client的状态
  const print = () => {
    console.log(client.current.state instanceof Client.Synchronized);
    console.log(client.current.state instanceof Client.AwaitingConfirm);
    console.log(client.current.state instanceof Client.AwaitingWithBuffer);
  };
  // socket
  const clientReceive = useCallback((obj) => {
    print();
    if (obj.client_id == clientId.current) {
      if (!(client.current.state instanceof Client.Synchronized))
        client.current.serverAck();
    } else {
      if (!obj?.nickname) {
        client.current.applyServer(
          TextOperation.fromJSON(JSON.parse(obj.body).ops),
        );
        creationStores.delData(JSON.parse(client.current.document));
      }
    }
  }, []);
  // 处理WebSocket的连接和消息处理

  function inherit(Const, Super) {
    function F() {
      //
    }
    F.prototype = Super.prototype;
    Const.prototype = new F();
    Const.prototype.constructor = Const;
  }
  // 定义一个MyClient类，继承自Client类，
  function MyClient(userId, document, revision, ws) {
    Client.call(this, revision);
    this.userId = userId;
    this.document = document;
    this.ws = ws;
  }
  inherit(MyClient, Client);

  // 向服务端发送数据
  MyClient.prototype.sendOperation = function (revision, operation) {
    this.ws.send(
      JSON.stringify({
        method: 2,
        body: JSON.stringify({ revision, ops: operation }),
      }),
    );
  };
  MyClient.prototype.applyOperation = function (operation) {
    this.document = operation.apply(this.document);
  };
  const userListRef = useRef(null);
  useEffect(() => {
    userListRef.current = userList;
  }, [userList]);
  MyClient.prototype.performOperation = function (oldDoc, newDoc) {
    const operation = patchToOperation(oldDoc, newDoc);
    this.document = newDoc;
    this.applyClient(operation);
  };
  const getShareDocFun = async (doc_id) => {
    // const teme = await getTeamDetail();
    const res = await getShare_doc({
      doc_id,
      doc_source: "script",
    });
    const item = res?.data;
    const obj = res?.data || [];
    if (!res) {
      return;
    }
    creationStores.delData(JSON.parse(item?.doc) || []);
    setCreationName(item.title || "");
    creationNameRef.current = item.title || "";
    userId.current = Login.uuid;
    //
    try {
      ws = new WebSocket(
        `/api/v1/share_doc/watch?client_id=${clientId.current}&uid=${Login.uuid}&doc_id=${doc_id}&doc_source=script&Token=${token}`,
      );
    } catch (error) {
      console.log(error);
    }
    ws.onmessage = (ev) => {
      const wsInfo = JSON.parse(ev.data);
      let info: any = {};
      if (wsInfo) {
        info = JSON.parse(wsInfo.body);
      }
      const newUserList = [...userList];
      switch (wsInfo.method) {
        case 5:
          setUserList(Object.values(info));
          if (Object.values(info).length == 1) {
            clientReceive(Object.values(info)[0]);
          } else {
            Message.success("开始协同");
          }
          break;
        case 4:
          newUserList.push(info);
          clientReceive(info);
          setUserList(newUserList);
          break;
        case 3:
          setOutInfo(info);
          break;
        case 2:
          setUpdateTime(info.updated_at);
          clientReceive(wsInfo);

          break;
        case 1:
          // {"method":1,"body":"{\"RowId\":0,\"ColId\":1,\"position\":21,\"uid\":\"536257773457946664\",\"range\":{\"from\":21,\"to\":21}}","uid":"536257773457946664"}
          if (!cursorInfo[0]?.userInfo?.length) {
            setCursorInfo([
              {
                RowId: info.RowId,
                ColId: info.ColId,
                userInfo: [
                  {
                    id: info.uid,
                    name: userListRef.current.find((v) => v.uid == info.uid)
                      ?.nickname,
                    position: info.position,
                    colorInfo: userListRef.current.find(
                      (v) => v.uid == info.uid,
                    )?.avater_color,
                    range: info.range,
                  },
                ],
              },
            ]);
          } else {
            if (
              cursorInfo[0].userInfo.length &&
              cursorInfo[0].userInfo.every((v) => v.id != info.uid)
            ) {
              cursorInfo[0].userInfo.push({
                id: info.uid,
                name: userListRef.current.find((v) => v.uid == info.uid)
                  ?.nickname,
                position: info.position,
                colorInfo: userListRef.current.find((v) => v.uid == info.uid)
                  ?.avater_color,
                range: info.range,
              });
              setCursorInfo([...cursorInfo]);
            }
          }

          break;

        default:
          break;
      }
    };
    ws.onclose = () => {
      console.log("链接断开");
    };
    ws.onopen = () => {
      dataSendFUn();
    };
    ws.onerror = (error) => {
      console.error("WebSocket 发生错误:", error);
    };
    client.current = new MyClient(1, obj.doc, obj.revision, ws);
  };

  const getScriptFun = async (id) => {
    const res = await getScript(id);
    if (res?.data) {
      getShareDocFun(res.data.item.id);
    }
  };
  const getDragDropCol = () => {
    let newColumns = [];
    newColumns = menuData
      .filter((item) => item.checked) // 筛选出 check 为 true 的项
      .map((item) => {
        // 在原始的 originalA 中找到对应的对象
        const aItem = columns.find((aItem) => aItem.title === item.title);
        return aItem ? aItem : { key: "", title: item.title }; // 如果找到则返回，否则返回一个默认对象
      });
    return [...columns.slice(0, columns.length - 6), ...newColumns];
  };
  useEffect(() => {
    if (!params?.id) {
      getShareDocFun(locations.state.id);
    } else {
      getScriptFun(params.id);
    }
  }, [clientReceive]);

  useEffect(() => {
    if (isEditName) {
      editNameRef.current.focus();
    }
  }, [isEditName]);
  useEffect(() => {
    setUserList(userList.filter((v) => v.client_id != outInfo));
  }, [outInfo]);
  useEffect(() => {
    return () => {
      if (ws) {
        ws.close();
      }
    };
  }, []);
  return (
    <>
      <ContentHeader
        name="创建脚本"
        subName="实现你的无限创意,在这里可以尽情发挥你的才艺和创意"
      />
      <Modals
        width={769}
        height={594}
        title="导入脚本"
        cancelBtn={<Button onClick={closeModal}>取消</Button>}
        subMiteBtn={
          <Button
            disabled={!getJson.length}
            type="primary"
            onClick={() => {
              Modal.confirm({
                title: "温馨提示!",
                content: "导入脚本后当面页面内容将全部被替换是否直接导入？",
                okButtonProps: {
                  status: "danger",
                },
                onOk: () => {
                  importScript();
                },
              });
            }}>
            保存
          </Button>
        }
        titleIcon={<Image width={18} preview={false} src={inscript_icon} />}
        closeIcon={<IconClose onClick={closeModal} />}
        visible={visibleModal}>
        <div className="modal-content">
          <div className="modal-download">
            <div>请先下载Excel数据导入模版，并按照模版导入数据</div>
            <a href="/template.xlsx" download>
              <Image width={18} preview={false} src={download_icon} />
              <span>下载Excel模版</span>
            </a>
          </div>
          <FilesUpload getJson={setGetJson} visible={visibleModal} />
        </div>
      </Modals>
      <CreateEngineeringModal
        submit={async (e) => {
          try {
            const data = await createEngineering(e);
            const scriptId = params?.id || locations?.state?.id;
            const json = await toIdGetCustomScript(scriptId);
            const content = pureParseCutsomScript(json);
            await saveEngineeringContent(
              Number(data.id),
              JSON.stringify(content),
            );
            window.open(`/mix-detail/${data.id}`, "_blank");
          } catch (e) {
            console.log(e);
            Message.error(e);
          }
        }}
        title={"创建项目"}
        visible={createModalVisible}
        value={{}}
        onCancel={() => {
          setCreateModalVisible(false);
        }}
        onOk={(e) => {
          setCreateModalVisible(false);
        }}
      />
      <div className="creation-content">
        {/* <div className="table-anchor">
          <TableAnchor
            columns={getDragDropCol()}
            send={dataSendFUn}
            handleClickPosition={(index, i) => {
              setTablePosition({
                row: index,
                col: i,
              });
            }}
          />
        </div> */}
        <Image
          preview={false}
          className="suspend-ai"
          width={62}
          src={suspend_ai_icon}
          style={{ display: suspendAi ? "none" : "block" }}
          onClick={() => {
            setSuspendAi(true);
            setSelectStatus(null);
            setSendSmartStatus("");
          }}
        />
        <div className="content-left">
          <div className="creation-tool">
            <div style={{ flex: 1, marginTop: 19 }}>
              <div className="creation-name">
                <Image
                  src={input_icon}
                  style={{ marginRight: 11, cursor: "pointer" }}
                  width={22}
                  preview={false}
                  onClick={() => {
                    setIsEditName(true);
                  }}
                />

                {isEditName ? (
                  <Input
                    className="creation-title"
                    style={{ maxWidth: 400 }}
                    ref={editNameRef}
                    value={creationName}
                    maxLength={20}
                    onChange={(e) => {
                      setCreationName(e);
                    }}
                    onBlur={() => {
                      setIsEditName(false);
                      if (creationNameRef.current == creationName) {
                        return;
                      }
                      editScriptFun("title");
                    }}
                    placeholder="请输入脚本名称"
                  />
                ) : (
                  <p className="creation-title">{creationName}</p>
                )}
              </div>
              <div className="creation-name-border"></div>
            </div>
            {userList && userList.length ? (
              <p className="save">
                {dayjs(updateTime * 1000)
                  .format("YYYY-MM-DD HH:mm")
                  .slice(-5)}
                &nbsp;已保存
              </p>
            ) : (
              <></>
            )}

            <div className="creation-btn-box" style={{ marginLeft: 10 }}>
              <AvatarGroup className="creation-avatarGroup">
                {userList.map((v, i) => {
                  if (i > 10) {
                    return (
                      <Avatar
                        key={v.uid}
                        style={{
                          background: "#eeeeee",
                          border: "none",
                          width: 30,
                          height: 30,
                          display: "none",
                        }}>
                        <div className="avatar-num">&nbsp;{v.nickname}</div>
                      </Avatar>
                    );
                  } else {
                    return (
                      <Avatar key={v.uid}>
                        <Tooltip
                          position="top"
                          trigger="hover"
                          content={v.nickname}>
                          {v.avatar && !v.avatar.includes("linear-gradient") ? (
                            <Image
                              src={v.avatar}
                              width={30}
                              style={{ borderRadius: "50%" }}
                            />
                          ) : (
                            <div
                              className="user-image"
                              style={{ background: v.avater_color || "" }}>
                              {v.nickname.slice(-2) || `${++i}`}
                            </div>
                          )}
                        </Tooltip>
                      </Avatar>
                    );
                  }
                })}
              </AvatarGroup>
              <Button
                className="import-script-btn"
                icon={
                  <Image
                    src={import_icon}
                    style={{ marginRight: 8 }}
                    width={16}
                    preview={false}
                  />
                }
                onClick={() => {
                  setVisibleModal(true);
                }}>
                导入脚本
              </Button>

              <Dropdown droplist={dropList()} trigger="click" position="bl">
                <Button
                  className="add-column-btn"
                  icon={
                    <Image
                      src={add_column_icon}
                      style={{ marginRight: 8 }}
                      width={16}
                      preview={false}
                    />
                  }>
                  添加列
                </Button>
              </Dropdown>

              <Button
                className="save-btn"
                icon={
                  <Image
                    src={filmDditingIcon}
                    style={{ marginRight: 8 }}
                    width={16}
                    preview={false}
                  />
                }
                onClick={() => setCreateModalVisible(true)}>
                去混剪
              </Button>
            </div>
          </div>

          <div className="creationScript-table">
            <DragDrop
              tablePosition={tablePosition}
              columns={getDragDropCol()}
              insertAndUpdateKeys={insertAndUpdateKeys}
              defaultInitialData={defaultInitialData}
              sendInfo={dataSendFUn}
              cursorInfo={cursorInfo}
            />
          </div>
          <div className="table_bottom-hr">
            <div></div>
            <div>
              <Button
                className="add-grouping"
                icon={
                  <Image
                    src={add_grouping_icon}
                    style={{ marginRight: 8 }}
                    width={16}
                    preview={false}
                  />
                }
                onClick={() => {
                  if (creationStores.dataSource.length < 15) {
                    creationStores.delData(
                      insertAndUpdateKeys(
                        creationStores.dataSource,
                        "add",
                        defaultInitialData,
                        -1,
                      ),
                    );
                    Message.success("添加成功");
                    const tableDom = document.querySelector(
                      ".creationScript-table-container",
                    );
                    setTimeout(() => {
                      tableDom.scrollTo({
                        top: tableDom.scrollHeight,
                        behavior: "smooth",
                      });
                    }, 200);
                    dataSendFUn();
                  } else {
                    Message.warning("分镜组个数最多为15个");
                  }
                }}>
                添加分组
              </Button>
            </div>
            <div></div>
          </div>
        </div>
        <div className="content-right">
          <div
            style={{
              width: sendSmartStatus ? 432 : 0,
              opacity: sendSmartStatus ? 1 : 0,
              transition: `width 0.3s ease,opacity 0.${sendSmartStatus ? 9 : 2}s ease`,
              marginLeft: selectStatus || suspendAi || smartStatus ? 21 : 0,
            }}>
            <Intelligent
              defaultStatus={smartStatus}
              value={sendSmartStatus}
              isInExecution={setItelligentIsExecution}
              handleOnChange={(e) => {
                handleClickInterlligent(e);
              }}
              handleCloseBtnClick={() => {
                setSendSmartStatus("");
              }}
            />
          </div>
          <div
            style={{
              width: suspendAi ? 432 : 0,
              opacity: suspendAi ? 1 : 0,
              transition: `width 0.3s ease,opacity 0.${suspendAi ? 9 : 2}s ease`,
            }}>
            <Assistant
              status={suspendAi}
              closeIcon={
                <IconClose
                  onClick={() => {
                    setSuspendAi(false);
                  }}
                  style={{ cursor: "pointer" }}
                />
              }
            />
          </div>
        </div>
      </div>
    </>
  );
}
