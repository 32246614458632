import React, { useEffect, useRef, useState } from "react";
import ContentHeader from "@/components/UI/ContentHeader";
import AnalysisDetails from "../component/AnalysisDetails";
import { inject, observer } from "mobx-react";
import "../index.less";
import { useNavigate, useParams } from "react-router-dom";
import ProgressCircle from "../component/ProgressCircle";
const VideoDetil = (props: any) => {
  const navigator = useNavigate();
  const { videoallyis } = props;

  const [anaydetil, setanaydetil] = useState(false);

  const handelClik = () => {
    // setanaydetil(false);
    // videoallyis.detilsbole = false;
    navigator("/analysis-video");
  };
  useEffect(() => {
    if (videoallyis.detilsbole) {
      setanaydetil(true);
    }
  }, [videoallyis.detilsbole]);

  // const [progress, setProgress] = useState(0);
  // const [targetProgress, setTargetProgress] = useState(0);
  // let intervalRef = null;
  // const iterationRef = useRef<number>(0);
  // const simulateProgress = (nextProgress: number) => {
  //   setTargetProgress(nextProgress);
  //   if (intervalRef !== null) {
  //     clearInterval(intervalRef);
  //   }
  //   intervalRef = setInterval(() => {
  //     iterationRef.current += 1;
  //     if (iterationRef.current < nextProgress) {
  //       setProgress(iterationRef.current);
  //     } else {
  //       setProgress(nextProgress);
  //       clearInterval(intervalRef);
  //       intervalRef = null;
  //     }
  //   }, 500);
  // };

  // useEffect(() => {
  //   return () => {
  //     if (intervalRef !== null) {
  //       clearInterval(intervalRef);
  //     }
  //   };
  // }, []);
  // const Hnadeclcik = (e) => {
  //   simulateProgress(Number(e));
  // };

  return (
    <>
      <div className="videoallyis_detil_header">
        <ContentHeader
          name="AI视频解析"
          subName={<div>你想要的, 在这里可以找到</div>}
        />
      </div>

      <AnalysisDetails deilClik={handelClik}></AnalysisDetails>
    </>
  );
};
export default inject("videoallyis")(observer(VideoDetil));

{
  /* <input
type="text"
onChange={(e) => {
  Hnadeclcik(e.target.value);
}}
/>
<div style={{ padding: "20px" }}>

<ProgressCircle progress={progress} size={85} />
</div> 
*/
}
