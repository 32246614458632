import { ReactNode } from "react";
import "./ActionBarItem.less";
export interface IConfigItem {
  text?: string | ReactNode | (() => ReactNode | string);
  icon?: ReactNode;
  disabled?: boolean;
  onClick?: () => void;
  style?: React.CSSProperties;
}
const ActionBarItem: React.FC<IConfigItem> = (props) => {
  const { text, icon, disabled, onClick, style } = props;
  return (
    <>
      <li
        style={{ ...style }}
        className={`${disabled ? "disabled" : ""} action-bar-item`}
        onClick={(e) => {
          if (disabled) {
            return;
          }
          e.stopPropagation();
          onClick();
        }}>
        {icon ? (
          <div className="action-bar-item-icon-wrapper">{icon}</div>
        ) : (
          <></>
        )}
        {typeof text === "function" ? text() : text || "--"}
      </li>
    </>
  );
};

export default ActionBarItem;
