import React from "react";
// import { Carousel } from "@arco-design/web-react";
// import locale from "./locale";
import bannerimg from "@/assets/iconimage/bannerimg.png";

import textfoolt from "@/assets/iconimage/textfoolt.png";
import "./style/style.less";
import "./style/banner.less";
export default function LoginBanner() {
  const data = [
    {
      slogan: "login.banner.slogan1",
      subSlogan: "login.banner.subSlogan1",
      image:
        "http://p1-arco.byteimg.com/tos-cn-i-uwbnlip3yd/6c85f43aed61e320ebec194e6a78d6d3.png~tplv-uwbnlip3yd-png.png",
    },
    {
      slogan: "login.banner.slogan2",
      subSlogan: "login.banner.subSlogan2",
      image:
        "http://p1-arco.byteimg.com/tos-cn-i-uwbnlip3yd/6c85f43aed61e320ebec194e6a78d6d3.png~tplv-uwbnlip3yd-png.png",
    },
    {
      slogan: "login.banner.slogan3",
      subSlogan: "login.banner.subSlogan3",
      image:
        "http://p1-arco.byteimg.com/tos-cn-i-uwbnlip3yd/6c85f43aed61e320ebec194e6a78d6d3.png~tplv-uwbnlip3yd-png.png",
    },
  ];
  return (
    // <Carousel className="carousel" animation="fade">
    //   {data.map((item, index) => (
    //     <div key={`${index}`}>
    //       <div className="item">
    //         <div className="title">{item.slogan}</div>
    //         <div className="sub-title">{item.subSlogan}</div>
    //         <img alt="banner-image" className="image" src={item.image} />
    //       </div>
    //     </div>
    //   ))}
    // </Carousel>
    <div className="carousel">
      <div className="item">
        <div className="item_img_contel">
          <img alt="banner-image" className="banner_image" src={bannerimg} />
          <img className="textfoolt  banner_textfoolt_image" src={textfoolt} />
        </div>
      </div>
    </div>
  );
}
