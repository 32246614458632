import React from "react";
import Accountlogin from "./Accountlogin";
import Smslogin from "./Smslogin";
export default function LoginFormitem(props: any) {
  const passonchangeActive = () => {
    props.passonchang(true);
  };
  return (
    <div>
      {props.active === 0 && (
        <Accountlogin activepassword={passonchangeActive}></Accountlogin>
      )}
      {props.active === 1 && <Smslogin></Smslogin>}
    </div>
  );
}
