import React, { ReactNode } from "react";
import "./ActionBar.less";

interface IActionBarProps {
  children: ReactNode;
  style?: React.CSSProperties;
}

const ActionBar: React.FC<IActionBarProps> = (props) => {
  return <ul className="action-bar-wrapper">{props.children}</ul>;
};

export default ActionBar;
