import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "@/components/UI/Header";
import invit from "@/assets/Invitation/invitationmain.png";
import { Input, Button, Message } from "@arco-design/web-react";
import { inject, observer } from "mobx-react";
import submitet from "@/assets/Invitation/submitet.png";
import refuse from "@/assets/Invitation/refuse.png";
import expire from "@/assets/Invitation/expire.png";
import { Spin } from "@arco-design/web-react";
import {
  IconExclamationCircle,
  IconDoubleRight,
} from "@arco-design/web-react/icon";
import "./index.less";
import CreateTeam from "@/components/business/User/components/CreateTeam";
import { getIsTeam } from "@/services/user/user";
import User from "@/stores/userStore";
import { getLocationItem } from "@arco-design/web-react/es/_class/VirtualList/utils/itemUtil";
const Invitation = (props: any) => {
  const { invite } = props;
  const navigator = useNavigate();
  const location = useLocation();
  const [activeinv, setactiveinv] = useState(null);
  const [code, setCode] = useState("");
  const [loding, setloding] = useState(true);
  /*
  1 带加入 审核中 

  2 拒绝     

  3 正常 可编辑 未加入 未过期

  4 成功 

  5 过期了 
  */
  useEffect(() => {
    const code = location.search.split("=")[1];
    if (!code) return;
    if (!localStorage.getItem("Token")) {
      setTimeout(() => {
        navigator(`/login?invite?code=${code}`);
      }, 1000);
    } else {
      invite.invite_invite_to(code);
    }

    setCode(code);
  }, [location]);

  useEffect(() => {
    setactiveinv(invite.codestate);
    if (invite.codestate != null) {
      setloding(false);
    }
    if (invite.codestate == "4") {
      setloding(false);
      setTimeout(() => {
        Message.success(`你已经加入该团队`);
        navigator("/team-anagement");
      }, 1000);
    }
  }, [invite.codestate]);

  const Reninviamin = () => {
    const [dataform, setdataform] = useState("");
    const [visible, setvisible] = useState(false);
    const [isTeam, setisTeam] = useState(false);
    const handleChangeMoadl = () => {
      setvisible(!visible);
    };
    const getTme = async () => {
      setvisible(!visible);
      const res = User.teamInfo.team_user_role;
      setisTeam(res.team.name == null ? true : false);
    };
    const data = {
      name: invite.merbname || "",
      depe: invite.name,
      changvealue: (e) => {
        setdataform(e);
      },
      subming: () => {
        if (dataform != "") {
          invite
            .invite_meber_to({ code: code, member_name: dataform })
            .then((res) => {
              if (res) {
                setTimeout(() => {
                  navigator("/work-bench");
                }, 1500);
              }
            });
        } else {
          Message.error(`加入团队输入的名称不能为空`);
        }
      },
    };
    return (
      <>
        <div className="Invitation_img">
          <div className="invbody_main_img">
            <div className="invbody_main_imgpoab">
              <p className="invbody_name">亲爱的用户:{data.name}</p>
              <p className="invbody_depe">
                <span className="depe_wegin">[{data.depe}]</span>
                {`  团队向您发出邀请，期待您的加入!`}
              </p>
              <Input
                maxLength={20}
                showWordLimit
                placeholder="请输入你在团队内的姓名"
                onChange={data.changvealue}
              />
              <Button type="primary" onClick={data.subming}>
                立即加入
              </Button>
              <p className="invbody_time">
                <IconExclamationCircle />
                <span>请在链接有效期2小时内操作</span>
              </p>
              <p
                className="invbody_timegoli"
                style={{
                  cursor: "pointer",
                  display:
                    User.teamInfo.teamList?.team_id > 0 ? "none" : "block",
                }}
                onClick={() => {
                  getTme();
                }}>
                暂不加入, 创建自己的团队 <IconDoubleRight />
              </p>
            </div>
            <CreateTeam
              isTeam={isTeam}
              visible={visible}
              handleChangeMoadl={handleChangeMoadl}></CreateTeam>
          </div>
        </div>
      </>
    );
  };
  const Reninvsubmitted = (props) => {
    const { type } = props;
    // const data = [
    //   {
    //     img: submitet,
    //     msg: false,
    //     title: "加入团队申请已提交, 请耐心等待审核",
    //   },
    //   {
    //     img: refuse,
    //     msg: false,
    //     title: "管理员已拒绝您的加入申请",
    //   },
    //   {
    //     img: expire,
    //     msg: "邀请链接已过期",
    //     title: "邀请链接已过期, 请联系管理员提供新的邀请链接",
    //   },
    // ];
    return (
      <>
        <div className="inv_contentil">
          <div className="inv_active_tabconten">
            <div className="inv_tabbody">
              {type == "1" && (
                <>
                  <img src={submitet} alt="" />
                  <p className="invbody_title">
                    加入团队申请已提交, 请耐心等待审核
                  </p>
                </>
              )}
              {type == "2" && (
                <>
                  <img src={refuse} alt="" />
                  <p className="invbody_title">管理员已拒绝您的加入申请</p>
                </>
              )}
              {type == "5" && (
                <>
                  <img src={expire} alt="" />
                  <p className="invbody_pottile">邀请链接已过期</p>
                  <p className="invbody_title" style={{ cursor: "pointer" }}>
                    邀请链接已过期, 请联系管理员提供新的邀请链接
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
      </>
    );
  };

  // info 过期    error 拒绝   submit  已经提交
  return (
    <div className="Invitation">
      <div className="Invitation_body">
        {loding && (
          <div
            className="Invitation_img"
            style={{
              display: "flex",
              flexDirection: "column",
            }}>
            <Spin dot />
            <p>审核当前邀请链接中</p>
          </div>
        )}
        {activeinv == 3 && <Reninviamin></Reninviamin>}
        {activeinv != 3 && <Reninvsubmitted type={activeinv}></Reninvsubmitted>}
      </div>
    </div>
  );
};
export default inject("invite")(observer(Invitation));
