import { Tree } from "@arco-design/web-react";
import { TreeDataType } from "@arco-design/web-react/es/Tree/interface";
import { useState } from "react";
import "./index.less";
interface IFormTreeItem {
  value?: string[];
  treeData?: TreeDataType[];
  onChange?: (e: string[]) => void;
}

const FormItemTree: React.FC<IFormTreeItem> = (props) => {
  const { value, treeData, onChange } = props;

  const [checkStrictly] = useState(false);
  return (
    <div className="itemtree-box">
      <Tree
        // defaultExpandedKeys={}
        autoExpandParent={true}
        checkStrictly={checkStrictly}
        checkable
        checkedKeys={value}
        onCheck={(value, extra) => {
          if (onChange) {
            console.log(value, extra);
            onChange(value);
          }
        }}
        // treeData
        treeData={treeData}
      />
    </div>
  );
};

export default FormItemTree;
