import React, { useEffect, useRef, useState } from "react";
import { Form, Button, Space, Input, Message } from "@arco-design/web-react";
import "./style/style.less";
import logoradioa from "@/assets/iconimage/radioa.png";
import logoradiob from "@/assets/iconimage/radiob.png";
import { observer, inject } from "mobx-react";
import { useLocation, useNavigate } from "react-router-dom";
import User from "@/stores/userStore";
const FormItem = Form.Item;
type Psswordone = {
  color: string;
  nub: number;
  numbers: number[] | undefined;
};
const Register = (props: any) => {
  const { login } = props;
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const FormreGist = useRef(form);
  const [radioopp, setradioopp] = useState(0);
  const [psswordone, setpsswordone] = useState<Psswordone>({
    color: "",
    nub: -1,
    numbers: undefined,
  });
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const [urlstate, setstate] = useState(null);
  useEffect(() => {
    try {
      const code = location.search.split("=")[1];
      setstate(code);
    } catch (e) {}
  }, [location]);

  async function onSubmitClick(datalist) {
    if (!radioopp) {
      Message.info("请勾选用户协议!");
    } else {
      setLoading(true);
      await login.register_to(datalist);
      setLoading(false);
    }
  }
  useEffect(() => {
    if (login.isSuccess) {
      setTimeout(() => {
        if (urlstate) {
          navigate(`/invite?code=${urlstate}`);
        } else {
          navigate("/");
        }
        login.isSuccess = false;
      }, 600);
    }
  }, [login.isSuccess]);

  //验证国际手机号码+86
  async function validatePhoneNumber(value, callback) {
    const element = document.getElementById("opa") as HTMLButtonElement;
    const regex = /^1(3|4|5|6|7|8|9)\d{9}$/;
    if (!value) {
      element.disabled = true;
      element.style.color = "#666666";
      callback(<div>手机号不能为空！</div>);
    } else if (!regex.test(value)) {
      callback(<div>手机号格式不正确,请重新输入</div>);
      element.disabled = true;
      element.style.color = "#666666";
      element.style.cursor = "default";
    } else {
      await login.exist_phone_number_to(FormreGist).then((res) => {
        if (res == "ok") {
          element.disabled = false;
          element.style.color = "#165DFF";
          element.style.cursor = "pointer";
          callback();
        } else if (res == "no") {
          element.disabled = true;
          element.style.color = "#666666";
          element.style.cursor = "default";
          callback(<div>当前手机号已存在,可直接登陆</div>);
        }
      });
    }
  }

  async function validateCaptcha(value, callback) {
    if (!value) {
      callback(<div>验证码不能为空</div>);
    } else {
      await login.verifyCode_to(FormreGist).then((res) => {
        if (res == "ok") {
          callback();
        } else if (res == "no") {
          callback(<div>验证码错误</div>);
        }
      });
    }
  }

  function validatePassword(value, callback) {
    const elementnode = document.querySelector(
      ".passQDessop",
    ) as HTMLDivElement;
    if (!value) {
      if (elementnode) {
        elementnode.style.display = "none";
      }

      PasswordStrength();
      callback(<div>密码不能为空</div>);
    } else if (!/^.{6,}$/.test(value)) {
      if (elementnode) {
        elementnode.style.display = "none";
      }
      PasswordStrength();
      callback(
        <div>请输入6-12个字符，支持数字、大小写字母和符号至少包含一种</div>,
      );
    } else {
      if (elementnode) {
        elementnode.style.display = "none";
      }
      callback();
      PasswordStrength();
    }
  }

  const validatePasswordConfirm = (value, callback) => {
    const { getFieldValue } = FormreGist.current;
    const password = getFieldValue("password");
    const popp = getFieldValue("popp");
    if (!popp) {
      callback(<div>密码不能为空</div>);
    } else if (!/^.{6,}$/.test(popp)) {
      callback(
        <div>请输入6-12个字符，支持数字、大小写字母和符号至少包含一种</div>,
      );
    } else if (!password) {
      callback(<div>请设置密码，并再次输入密码</div>);
    } else if (password && popp !== password) {
      callback(<div>两次密码输入不一致，请重新输入</div>);
    } else {
      callback();
    }
  };

  const PasswordStrength = () => {
    const { getFieldValue } = FormreGist.current;
    const password = getFieldValue("password");
    let strength = -1;
    if (password && password.length > 5) {
      if (/[0-9]/.test(password)) strength += 1;
      if (password.length >= 8) {
        if (/[a-z]/.test(password)) strength += 1;
        if (/[A-Z]/.test(password)) strength += 1;
        if (/\W/.test(password)) strength += 1;
      }
    }
    const level = Math.min(strength, 3);
    let levelop;
    if (level == -1 || level == 0) {
      levelop = 1;
    } else {
      levelop = level;
    }
    const color = ["red", "#FFA5A5", "#faad14", "#A7CBFF", "#A7CBFF"][levelop];
    const numbers = Array.from({ length: levelop }, (_, index) => index);
    setpsswordone({ color, nub: level, numbers: numbers });
  };
  useEffect(() => {
    const element = document.getElementById("opa") as HTMLButtonElement;
    element.disabled = true;
    element.style.color = "#666666";
    element.style.backgroundColor = "transparent";
    if (element) {
      const handleClick = (element) => {
        login.sendCode_to(FormreGist);
        const et = element;
        et.target.disabled = true;
        et.target.style.color = "#666666";
        let count = 10;
        const countdownInterval = setInterval(() => {
          et.target.style.cursor = "default";
          et.target.innerHTML = `${count - 1}秒后重发`;
          count--;
          if (count <= 0) {
            const { getFieldValue } = FormreGist.current;
            const phone_number = getFieldValue("phone_number");
            const regex = /^1(3|4|5|6|7|8|9)\d{9}$/;
            if (!regex.test(phone_number)) {
              et.target.innerHTML = `重新发送`;
              et.target.style.color = "#666666";
              clearInterval(countdownInterval);
              et.target.disabled = true;
              et.target.style.cursor = "default";
            } else {
              et.target.innerHTML = `重新发送`;
              et.target.style.color = "#165DFF ";
              clearInterval(countdownInterval);
              et.target.disabled = false;
              et.target.style.cursor = "pointer";
            }
          }
        }, 1000);
      };
      element.addEventListener("click", handleClick);
      return () => {
        element.removeEventListener("click", handleClick);
      };
    }
  }, []);
  return (
    <div className="toppoab">
      <span className="login-form-active" id="regist">
        欢迎注册
      </span>
      <Form id="regist" ref={FormreGist} autoComplete="off">
        <FormItem
          field="phone_number"
          rules={[
            { validator: validatePhoneNumber, validateTrigger: "onBlur" },
            {},
          ]}>
          <Input
            className="form-contorinput"
            allowClear={true}
            placeholder="请输入手机号"
          />
        </FormItem>
        <FormItem
          field="code"
          rules={[{ validator: validateCaptcha, validateTrigger: "onBlur" }]}>
          <Input
            placeholder="请输入验证码"
            suffix={
              <div className="inputyzmbody">
                <div id="inputyzmi"></div>
                <button id="opa">获取验证码</button>
              </div>
            }
          />
        </FormItem>
        <FormItem
          className="registformpssword"
          field="password"
          rules={[
            { validator: validatePassword, validateTrigger: ["onBlur"] },
          ]}>
          <Input.Password defaultValue="password" placeholder="设置密码" />
        </FormItem>
        {psswordone.nub != -1 && (
          <div className="passQDL">
            <div className="passQDleft">
              密码强度：
              {psswordone.numbers &&
                psswordone.numbers.length &&
                psswordone.numbers.map((item, index) => {
                  return (
                    <div key={index} className="passQDleftdiv">
                      <p
                        style={{ backgroundColor: psswordone.color }}
                        className="psswordonecolor"></p>
                      <p
                        style={{ backgroundColor: psswordone.color }}
                        className="psswordonecolor"></p>
                    </div>
                  );
                })}
            </div>
            <p className="lpassQDright">
              {psswordone.nub === 1 || psswordone.nub === 0
                ? "弱"
                : psswordone.nub === 2
                  ? "中"
                  : "强"}
            </p>
          </div>
        )}
        <div className="passQDessop">
          6-12个字符，支持数字、大小写字母和符号至少包含一种
        </div>

        <FormItem
          field="popp"
          rules={[
            { validator: validatePasswordConfirm, validateTrigger: "onBlur" },
          ]}>
          <Input.Password defaultValue="password" placeholder="再次输入密码" />
        </FormItem>
        <Space direction="vertical" style={{ marginTop: "22px" }}>
          <Button
            type="primary"
            long
            onClick={async () => {
              if (FormreGist.current) {
                try {
                  const value = await FormreGist.current.validate();
                  onSubmitClick({ ...value, ...psswordone });
                } catch (_) {
                  console.error();
                }
              }
            }}
            loading={loading}
            className="styleInputbutton">
            注册
          </Button>
          <div className="radiobody" style={{ cursor: "pointer" }}>
            {radioopp === 0 ? (
              <div
                className="logi_form_titlerekeli"
                onClick={() => {
                  setradioopp(1);
                }}>
                <p className="ardioactiveer"></p>
                <span>我已阅读并同意</span>
              </div>
            ) : (
              <div
                className="logi_form_titlerekeli"
                onClick={() => {
                  setradioopp(0);
                }}>
                <img src={logoradiob} alt="" className="ardioactive" />
                <span>我已阅读并同意</span>
              </div>
            )}
            <span
              className="login-form-itemhover"
              onClick={() => {
                alert("11");
              }}>
              用户协议, 隐私政策, 产品服务协议
            </span>
          </div>
        </Space>
      </Form>
    </div>
  );
};
export default inject("login")(observer(Register));
