import { Button, Checkbox } from "@arco-design/web-react";
import "./BatchCollection.less";
export interface IBatchCollection {
  handleSelectAll?: () => void;
  handleCancel?: () => void;
  number: number;
  total: number;
  disabeldAll?: boolean;
  disabled?: boolean;
}

const BatchCollection: React.FC<IBatchCollection> = (props) => {
  const {
    handleCancel,
    handleSelectAll,
    number,
    total,
    disabeldAll,
    disabled,
  } = props;
  return (
    <div className="checkbox-wrapper">
      {!disabeldAll ? (
        <Checkbox
          disabled={disabled}
          checked={total === number}
          onChange={(e) => {
            if (e) {
              handleSelectAll();
            } else {
              handleCancel();
            }
          }}>
          全选
        </Checkbox>
      ) : (
        <></>
      )}
      {number > 0 ? (
        <>
          <span className="tip">已选</span>
          <span className="num-tip">{number}</span>
          <span>
            <Button type="text" onClick={handleCancel} disabled={disabled}>
              取消
            </Button>
          </span>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default BatchCollection;
