import { Divider, Tabs } from "@arco-design/web-react";
import React, { useState } from "react";
import "./style/style.less";
import FormItme from "./loginformitem";
import Register from "./register";
import Forgotpassword from "./Forgotpassword";
export default function LoginForm() {
  const [fromactive, setfromactive] = useState(0);
  const [loginregister, setloginregister] = useState(0);
  const TabPane = Tabs.TabPane;

  const [forgotpassword, setforgotpassword] = useState(false);
  const handefogetpasword = (e) => {
    if (!forgotpassword && e) setforgotpassword(true);
  };

  const handeformpassrod = (activeprops: boolean) => {
    if (activeprops) {
      setforgotpassword(false);
      setloginregister(0);
      setfromactive(0);
    }
  };
  const AccountloginSMS = () => {
    return (
      <div>
        {loginregister === 0 && (
          <div>
            <div className="login-formtomsgt">
              <div>
                <span
                  className={
                    !fromactive ? "login-form-active" : "login-form-sub-title "
                  }
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setfromactive(0);
                  }}>
                  账号登录
                </span>
                {!fromactive && <p className="Tableactive"></p>}
              </div>
              <div>
                <span
                  className={
                    fromactive ? "login-form-active" : "login-form-sub-title "
                  }
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setfromactive(1);
                  }}>
                  短信登录
                </span>
                {fromactive ? <p className="Tableactive"></p> : ""}
              </div>
            </div>
            <div className="error-msg"></div>
            <FormItme
              active={fromactive}
              passonchang={handefogetpasword}></FormItme>
          </div>
        )}

        {loginregister === 1 && <Register></Register>}
        <div
          className="bttomactivehov"
          style={loginregister == 0 ? { bottom: "74px" } : { bottom: "-54px" }}>
          <div className="bottmbodyheader" style={{ cursor: "pointer" }}>
            {loginregister === 0 && (
              <Divider orientation="center">
                没有账号？
                <span
                  style={{ color: "#417AFF" }}
                  onClick={() => {
                    setloginregister(1);
                  }}>
                  点击注册
                </span>
              </Divider>
            )}
            {loginregister === 1 && (
              <Divider orientation="center">
                已有账号？
                <span
                  style={{ color: "#417AFF" }}
                  onClick={() => {
                    setloginregister(0);
                  }}>
                  点击登录
                </span>
              </Divider>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="login-form-wrapper" style={{ position: "relative" }}>
      {/* <Tabs key='card' inkBarSize={{width:'62px',height:'4px'}}>
        <TabPane key='1' title='账号登陆'>
          <Typography.Paragraph >Content of Tab Panel 1</Typography.Paragraph>
        </TabPane>
        <TabPane key='2' title='短信登陆'>
          <Typography.Paragraph >Content of Tab Panel 2</Typography.Paragraph>
        </TabPane>
      </Tabs> */}
      {forgotpassword ? (
        <Forgotpassword handeonclick={handeformpassrod}></Forgotpassword>
      ) : (
        <AccountloginSMS></AccountloginSMS>
      )}
    </div>
  );
}
