import { forwardRef, ReactElement, ReactNode, useState } from "react";
import floderImg from "@/assets/create-space/folder.png";
import projectImg from "@/assets/create-space/project.png";
import { IconMoreVertical } from "@arco-design/web-react/icon";
import { Checkbox, Popover } from "@arco-design/web-react";
import EditText, { IforwardRef } from "../EditText/EditText";
import "./ProjectCard.less";

export enum ProjectTypeEnum {
  FOLDER = "FOLDER",
  PROJECT = "PROJECT",
}

export interface IProjectCard {
  /**
   * 是否进行可以改名 默认可以改名
   */
  disRename?: boolean;
  /**
   * 是否可以重命名
   *
   */
  editDisabeld?: boolean;
  /**
   * 文件夹或者项目id
   */
  id?: string;
  /**
   * 两种模式 ProjectTypeEnum(文件夹 || 项目) ,默认PROJECT
   */
  type?: ProjectTypeEnum;
  /**
   * 是否可以批量操作
   */
  isOperate?: boolean;
  /**
   * 名称
   */
  name: string;
  /**
   * 更新时间
   */
  updateTime: string;
  /**
   * 右上角展示
   */
  rightTopTag?: ReactElement;
  /**
   * 单个卡片操作栏
   */
  actionBar?: ReactNode;
  /**
   * checkbox值
   */
  checkBoxState?: boolean;
  /**
   * 是否组织点击卡片事件
   */
  isCardClickPrevented?: boolean;

  /**
   * style 外层样式
   */
  style?: React.CSSProperties;
  /**
   * 点击卡片事件
   */
  onClick?: () => void;
  /**
   * 内部名称修改的input 键盘摁下enter事件
   */
  handleInputEndEnter?: (e: string) => void;
  /**
   * checkbox值变换事件
   */
  handleCheckboxChange?: (e: boolean) => void;
}

const ProjectCard = forwardRef<IforwardRef, IProjectCard>((props, ref) => {
  const {
    disRename,
    editDisabeld,
    updateTime,
    rightTopTag,
    actionBar,
    name,
    isOperate,
    checkBoxState,
    type,
    isCardClickPrevented,
    style,
    onClick,
    handleCheckboxChange,
    handleInputEndEnter,
  } = props;
  return (
    <div
      onClick={() => {
        if (onClick && !checkBoxState && !isCardClickPrevented) {
          onClick();
        }
      }}
      style={{
        ...style,
        border: `1px solid ${checkBoxState ? "#165DFF" : "transparent"}`,
        cursor: "pointer",
      }}
      className={`project-card-wrapper ${isOperate ? "opeater-box" : ""}`}>
      {isOperate ? (
        <div className={`checkbox-wrapper ${checkBoxState ? "show" : ""}`}>
          <Checkbox
            onClick={(e) => {
              e.stopPropagation();
            }}
            checked={checkBoxState}
            onChange={(e) => {
              if (handleCheckboxChange) {
                handleCheckboxChange(e);
              }
            }}
          />
        </div>
      ) : (
        <></>
      )}
      <div className="tag-wrapper">{rightTopTag}</div>
      {type === ProjectTypeEnum.FOLDER ? (
        <img src={floderImg} alt="folder Image" />
      ) : (
        <img src={projectImg} alt="project Image" />
      )}
      <EditText
        name={name}
        handleInputEndEnter={handleInputEndEnter}
        ref={ref}
        disabed={disRename}
      />
      <p className="project-update-time">更新:{updateTime}</p>
      {actionBar ? (
        <Popover position="rt" content={actionBar} trigger={"hover"}>
          <div className="more-opearte" style={{ cursor: "point" }}>
            <IconMoreVertical
              onClick={(e) => {
                e.stopPropagation();
              }}
            />
          </div>
        </Popover>
      ) : null}
    </div>
  );
});

ProjectCard.displayName = "ProjectCard";

export default ProjectCard;
